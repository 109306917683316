body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

span::selection {
  background: #000000;
  color: #ffffff;
}

.line {
  width: 100%;
  display: flex;
}

.line span {
  flex: 1;
  white-space: nowrap;
}

.App > button {
  top: 0em;
  left: 0;
  position: absolute;
  text-align: left;
  padding: 0.4em;
  font-weight: bold;
  width: 263px;
  border: none;
  border-radius: 0.2em;
  border-radius: 0;
}

button {
  color: #000;
}

.App > button:hover {
  color: red !important;
}

.gui {
  font-family: -apple-system, "Helvetica Neue", Helvetica, sans-serif;
  padding: 0.4em;
  top: 1.4em;
  left: 0;
  position: absolute;
  background-color: #f1f1f1;
  width: 250px;
  display: flex;
  justify-content: space-between;
}

.gui div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3em 0;
}

label {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
label span:nth-child(2) {
  margin-right: 1em;
  color: red;
}

input {
  background-color: transparent;
  max-width: 120px;
  font-size: 1em !important;
  padding: 0;
  width: 100%;
  border: none;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid rgb(0, 0, 0);
}

input[type="text"] {
  border-bottom: 1px solid rgb(0, 0, 0);
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  border-radius: 0 !important;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
input:focus,
select:focus {
  outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #000;
  margin-top: -2.5px; /* You need to specify a margin in px that lifts the thumb to the center of the track */
  cursor: pointer;
}

select {
  background-color: transparent;
  border: none;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
  color: #000;
  border-bottom: 1px solid rgb(0, 0, 0);
  width: 120px;
  padding: 0;
  margin: 0;
  height: 1.5em;
}

input[type="radio"]:after {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
}

input[type="radio"]:checked:after {
  background-color: red;
}
